<template>
    <div class="login">
        <div class="container">
            <div class="row">
                <div class="col-sm-9 col-md-7 col-lg-5 mx-auto">
                    <div class="card card-signin my-5">
                        <div class="card-body">
                            <b-alert v-if="loginError" show variant="danger">{{ loginError }}</b-alert>
                            <b-form @submit.prevent="loginSubmit">
                                <b-form-group label="Username:" label-for="username">
                                <b-form-input
                                        id="username"
                                        v-model="username"
                                        type="text"
                                        required
                                        placeholder="Your Username"
                                        autocomplete="username">
                                </b-form-input>
                            </b-form-group>
                                <b-form-group label="Password:" label-for="password">
                                    <b-form-input
                                            id="password"
                                            v-model="password"
                                            type="password"
                                            required
                                            placeholder="Your Password"
                                            autocomplete="current-password">
                                    </b-form-input>
                                </b-form-group>
                                <b-button type="submit">
                                    <div v-if="!loggingIn">Login</div>
                                    <div v-if="loggingIn" class="spinner-border" role="status">
                                     <span class="sr-only">Loading...</span>
                                    </div>
                                </b-button>

                            </b-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import {mapActions} from 'vuex';

    export default {
        name: "Login",
        data() {
            return {
                username: '',
                password: ''
            }
        },
        computed: {
            ...mapState([
                'loggingIn',
                'loginError',
                'loginSuccessful'
            ])
        },
        methods: {
            ...mapActions([
                'doLogin'
            ]),
            loginSubmit() {
                this.doLogin({
                    username: this.username,
                    password: this.password
                })
            }
        }
    }
</script>

<style scoped>

</style>